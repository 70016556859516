import { StaticQuery, Link, graphql } from "gatsby"
import React from "react"

import "./last-news.scss"

const LastNews = ({ number, title, sort, except, link }) => {

  return <>
    <StaticQuery
      query={graphql`
        query {
          allPrismicBlogPost{
            edges {
              node {
                uid
                data {
                  seo_title
                  seo_description
                  seo_page_thumbnail {
                    url
                  }
                  content_date
                }
              }
            }
          }
        }
      `
      }
      render={({
        allPrismicBlogPost: {
          edges
        }
      }) => {

        // Order posts by date
        if( sort === 'last' || !sort )
          edges.sort((a, b) => (a.node.data.content_date > b.node.data.content_date) ? -1 : 1);
        else if( sort === 'random' ){
          function randomize(a, b) {
            return Math.random() - 0.5;
          }
          edges.sort(randomize);
        }

        // Format the edges
        const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let text_date;

        let filtered_edges = [{}];

        if( except && except !== '' )
          number++

        for( let i in edges ){
          if( i < number ){
            text_date = month_names[ new Date(edges[i].node.data.content_date).getMonth() ] + ' ' + new Date(edges[i].node.data.content_date).getDate() + ', ' + new Date(edges[i].node.data.content_date).getFullYear();
            filtered_edges[i] = edges[i];
            filtered_edges[i].node.data.content_date = text_date;
          }
        }

        if( except && except !== '' ){
          for( let i in filtered_edges ){
            if( filtered_edges[i].node.uid === except )
              delete filtered_edges[i];
          }
        }

        function post_link( uid ){
          let link = '/news/' + uid + '/';
          return link;
        }

        function section_title(){
          if( link === true ){
            return <Link to="/news/" className="main-page-section__title">
              <i></i>
              <span className="main-page-section__title__text">
                {title}
              </span>
            </Link>
          }
          else{
            return <h3 className="main-page-section__title">
              <i></i>
              <span className="main-page-section__title__text">
                {title}
              </span>
            </h3>
          }
        }

        return <>
          <div className="last-news">
              {section_title()}
              <div className="last-news__line">
                {filtered_edges.map((edge, index) => (
                  <Link to={post_link( edge.node.uid )} key={`related_post_${index}`} className="last-news__item">
                    <span className="last-news__item__title">{edge.node.data.seo_title}</span>
                    <span className="last-news__item__date">{edge.node.data.content_date}</span>
                  </Link>
                ))}
              </div>
          </div>
        </>

      }}
    />
  </>

}

export default LastNews