import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import { isBrowser } from "../helpers/general-helper";
import LastNews from "../components/shared/last-news/last-news"
// import "./post.scss"


const Post = ( staticData ) => {


  // Merge Prismic Preview data if any.
  const previewData = isBrowser() && window.__PRISMIC_PREVIEW_DATA
  const { prismicBlogPost: { data, uid, tags } } = mergePrismicPreviewData({
    staticData: staticData.data,
    previewData
  })

  const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const text_date = month_names[ new Date(data.content_date).getMonth() ] + ' ' + new Date(data.content_date).getDate() + ', ' + new Date(data.content_date).getFullYear();

	const SEOData = {
		"seo_title": data.seo_title,
		"seo_description": data.seo_description,
		"seo_page_thumbnail": {...data.seo_page_thumbnail}
  }


  return (
    <Layout SEOData={SEOData}>

      <Link to="/" className="logo">Super!com</Link>
      {/* <Link to="/news/" className="title">News</Link> */}

      <div className="news news-post">

        <div className="center">

          <header className="news-post__header">
            <div className="news-post__header__published">
              Published in&nbsp;
              {tags.map((tag, index) => (
                <span key={`post_category_${index}`}>
                  <Link to={'/news/#' + tag.toLowerCase()} className="ui-link">{tag}</Link>
                  {index !== tags.length - 1 &&
                    <>
                      ,&nbsp;
                    </>
                  }
                </span>
              ))}
            </div>
            <h2 className="news-post__header__title">
              {data.content_title.text}
            </h2>
            <div className="news-post__header__date">
              {text_date}
            </div>
            <div className="news-post__header__image">
              <span className="news-post__header__image__image" style={{ backgroundImage: 'url(' + data.seo_page_thumbnail.url + ')' }}></span>
            </div>
          </header>

          <div className="news-post__body">

            <div className="news-post__body__content" dangerouslySetInnerHTML={{ __html: data.content_body.html.replace('<p></p>', '').replace(/<a/g, '<a target="_blank" rel="noopener noreferrer"') }}/>

            <div className="news-post__body__sidebar">
              <img src={data.author_photo.url} className="news-post__body__sidebar__image" alt=""/>
              <span className="news-post__body__sidebar__name">{data.author_name}</span>
              <span className="news-post__body__sidebar__position">{data.author_position}</span>
            </div>

          </div>

          {/* <div className="news-post__related">
            <h6 className="news-post__related__title">You may also like</h6>
            <div className="news-post__related__posts">
              <Link to="/" className="news-post__related__posts__item">111</Link>
              <Link to="/" className="news-post__related__posts__item">222</Link>
              <Link to="/" className="news-post__related__posts__item">333</Link>
            </div>
          </div> */}

          <LastNews number={3} title="You may also like" sort="random" except={uid} link={false}></LastNews>

        </div>

      </div>


    </Layout>
  )


}

export default Post

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {

      uid

      tags

      data {

        seo_title
        seo_description
        seo_page_thumbnail {
          url
          dimensions {
            width
            height
          }
        }

        content_date

        content_title {
          text
        }

        content_body {
          html
        }

        author_name
        author_position
        author_photo {
          url
        }

      }
    }
  }
`